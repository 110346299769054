<script>
import PartnerTreeMenu from '@/components/partner/tree-menu'

export default {
  props: {
    user: Object,
    searchByUser: Boolean,
    searchByParent: Boolean
  },
  components: {
    PartnerTreeMenu
  },
  data() {
    return {
      search: {

      },
      year: new Date().getFullYear().toString(),
      includeBelow: false,
      title: '',
      list: null,
      statData: null
    }
  },
  mounted () {
    if (this.searchByUser === false && this.searchByParent === false) {
      this.parentId = this.$store.getters['auth/user'].id
      this.$refs.partnerTreeMenu.getPartners().then()
    } else {
      this.searchList()
    }
  },
  methods: {
    setParent: function(id) {
      this.parentId = id
    },
    searchList: async function() {
      if (this.searchByUser === true && !this.user) return
      const loader = this.$loading.show({ container: this.$refs.listContainer})
      try {
        const date = new Date(parseInt(this.year), 12, 0)
        const day = date.getDate()
        const beginDate = new Date(this.year + '-01-01' + 'T00:00:00')
        const endDate = new Date(this.year + '-12-' + day + 'T00:00:00')

        const result = await this.$API.statistic.getStatBetDateByVendor(beginDate.toISOString(), endDate.toISOString(), { userId: this.user.id, parentId: this.parentId, includeBelow: this.includeBelow })
        if (result.code !== 0) {
          return alert(result.msg)
        }
        this.list = result.documents
        this.statData = result.statData

      } finally {
        loader.hide()
      }
    },
  },
  watch: {
    user: function(val) {
      if (val) {
        this.searchList()
      }
    }
  }
}
</script>

<template>
  <div ref="listContainer">

    <form class="header-search" @submit.prevent="searchList">
      <!-- 페이징 및 검색 //-->
      <div class="row">
        <div class="col-md-3">

        </div>
        <div class="col-md-4 mb-2">

        </div>
        <div class="col-md-2">
          <b-form-checkbox v-model="includeBelow" switch size="lg" @change="searchList">
            하부포함
          </b-form-checkbox>
        </div>
        <div class="col-md-2">
          <select class="form-select" v-model="year">
            <template v-for="y in ['2022', '2023', '2023', '2024', '2025', '2026', '2027', '2028', '2029', '2030']" :key="y">
              <option :value="y">{{ y }}{{ $t('년') }}</option>
            </template>
          </select>
        </div>
        <div class="form-group col-md-1 mb-2">
          <button type="submit" class="btn btn-dark w-100">검색</button>
        </div>
      </div>
    </form>

    <hr class="mt-0 mb-3" />

    <div class="w-100">
      <div class="d-md-flex">

        <!-- 상위 목록 //-->
        <partner-tree-menu :partner="$store.getters['auth/user']" @setParent="setParent" @searchList="searchList" ref="partnerTreeMenu" v-if="searchByUser === false && searchByParent === false"></partner-tree-menu>

        <!-- 목록 //-->
        <div class="table-responsive mb-0 rounded-3 w-100" style="overflow: auto;">
          <div class="w-100">
            <div class="card">
              <div class="card-body">
                <table class="table align-middle table-sm table-bordered table-nowrap sticky-table w-100">
                  <thead class="table-dark">
                  <tr>
                    <th>{{ $t('형식') }}</th>
                    <th>{{ $t('게임사') }}</th>
                    <th>{{ $t('배팅') }}</th>
                    <th>{{ $t('승') }}</th>
                    <th>{{ $t('무') }}</th>
                    <th>{{ $t('취소') }}</th>
                    <th>{{ $t('유효배팅') }}</th>
                    <th>{{ $t('Win/Lose') }}</th>
                    <th>{{ $t('환수율') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(category, index) in list" :key="'stat-' + index">
                    <tr v-for="(vendor, vendorIndex) in category.vendors" :key="'vendor-' + vendor._id">
                      <td :rowspan="category.vendors.length" v-if="vendorIndex === 0">{{ category.name }}</td>
                      <td>{{ vendor.names[$store.getters['auth/user'].language] }}</td>
                      <td class="text-end">{{ vendor.statData.betSum.floatVal(0).formatThousands() }}<!-- ({{ vendor.statData.betCount.formatThousands() }}) //--></td>
                      <td class="text-end">{{ vendor.statData.winSum.floatVal(0).formatThousands() }}<!--  ({{ vendor.statData.winCount.formatThousands() }}) //--></td>
                      <td class="text-end">{{ vendor.statData.drawSum.floatVal(0).formatThousands() }}<!--  ({{ vendor.statData.drawCount.formatThousands() }}) //--></td>
                      <td class="text-end">{{ vendor.statData.cancelSum.floatVal(0).formatThousands() }}<!--  ({{ vendor.statData.cancelCount.formatThousands() }}) //--></td>
                      <td class="text-end">{{ (vendor.statData.betSum - vendor.statData.drawSum - vendor.statData.cancelSum).floatVal(0).formatThousands() }}<!-- ({{ (vendor.statData.betCount - vendor.statData.drawCount - vendor.statData.cancelCount).formatThousands() }})//--></td>
                      <td class="text-end">{{ (vendor.statData.betSum - vendor.statData.drawSum - vendor.statData.cancelSum - vendor.statData.winSum).floatVal(0).formatThousands() }}<!-- ({{ (vendor.statData.betCount - vendor.statData.drawCount - vendor.statData.cancelCount - vendor.statData.winCount).formatThousands() }})//--></td>
                      <td>{{ vendor.statData.rtp }}%</td>
                    </tr>
                    <tr class="table-light" v-if="category.vendors.length > 0">
                      <td colspan="2">합계</td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.betSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.betCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.winSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.winCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.drawSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.drawCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.cancelSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.cancelCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.validBetSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.validBetCount.formatThousands() }}) //--></td>
                      <td class="text-end" style="font-weight: bold;">{{ category.statData.winloseSum.floatVal(0).formatThousands() }}<!-- ({{ category.statData.winloseCount.formatThousands() }}) //--></td>
                      <td style="font-weight: bold;">{{ category.statData.rtp }}%</td>
                    </tr>
                  </template>
                  <tr>
                    <td colspan="8"></td>
                  </tr>
                  </tbody>
                  <tfoot class="table-dark">
                  <tr v-if="statData">
                    <td colspan="2">합계</td>
                    <td class="text-end">{{ statData.betSum.floatVal(0).formatThousands() }}<!-- ({{ statData.betCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.winSum.floatVal(0).formatThousands() }}<!-- ({{ statData.winCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.drawSum.floatVal(0).formatThousands() }}<!-- ({{ statData.drawCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.cancelSum.floatVal(0).formatThousands() }}<!-- ({{ statData.cancelCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.validBetSum.floatVal(0).formatThousands() }}<!-- ({{ statData.validBetCount.formatThousands() }})//--></td>
                    <td class="text-end">{{ statData.winloseSum.floatVal(0).formatThousands() }}<!-- ({{ statData.winloseCount.formatThousands() }})//--></td>
                    <td>{{ statData.rtp }}%</td>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
